<template>
  <div class="view">
    <div class="content">
      <LogoBanner/>
      <div class="wrapper">

        <h2>QR Code erstellen</h2>

        <label class="editLabel">
          Name*:
          <input type="text" required v-model="name">
        </label>

        <label class="editLabel">
          Maximale Personen Anzahl*:
          <input type="number" required v-model="maxPerson">
        </label>

        <label class="editLabel">
          Normale Aufenthaltsdauer in Minuten*:
          <input type="number" required v-model="duration">
        </label>

        <label class="editLabel">
          Adresse*:
          <input type="text" required v-model="address">
        </label>

        <span style="color: red; margin-top: 16px">
        *Pflichtfeld
        </span>
        <br>
        <b-button variant="success" block @click="create">Erstellen</b-button>

        <b-button variant="danger" block @click="$router.push('/qr')">Abbrechen</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBanner from "../components/LogoBanner";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "NewOwnQrCode",
  components: {
    LogoBanner
  },
  data() {
    return {
      name: null,
      maxPerson: null,
      duration: null,
      address: null,
    }
  },
  computed: {
    ...mapGetters([
      'getQRCodes'
    ]),
    qrCodes() {
      return this.getQRCodes
    }
  },
  methods: {
    ...mapMutations([
        'addQrCode'
    ]),
    create() {
      let qr = {
        id: this.qrCodes.length,
        name: this.name,
        maxPerson: this.maxPerson,
        duration: this.duration,
        address: this.address,
        date: (new Date())
      }
      this.addQrCode(qr)
      this.$router.push('/qr/created')
    }
  }
}
</script>

<style scoped>
.content {
  height: 100%;
  max-height: 100%;
}

h1 {
  text-align: center;
  font-weight: bold;
}

.editLabel {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.editLabel input {
  background: #E5E5E5;
  border: 0;
  border-radius: 5px;
  outline: 0;
  height: 40px;
  padding: 4px;
}

.saveButton {
  margin-top: 24px;
  padding: 8px;
  width: 100%;
  background: #21BD64;
  color: white;
  border-radius: 8px;
}
</style>
